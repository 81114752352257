/*
 * @Author: 刘锦
 * @Date: 2020-10-28 16:53:44
 * @LastEditTime: 2022-03-01 11:05:33
 * @LastEditors: Seven
 * @Description:
 * @FilePath: 
 */

import { envUtil } from "@/utils/index"

// 商机状态枚举
export const bizStatusMap = {

}
//单据状态
export const billStatusMap = {
  0:"待审核",
  1:"撤销",
  2:"驳回",
  3:"通过",
}
//变更类型
export const changeTypeMap = {
  0:"变更招生",
  1:"变更销售",
}

// 多升多转选择学员的性别
export const studentSexMap = {
  0:"女",
  1:"男",
  2:"保密"
}

// 多升多转选择学员的帐号状态
export const studentAccMap = {
  0:"禁用",
  1:"正常",
}

// 收齐款枚举
export const allPayMap = {
  0: "否",
  1: "是"
}
// 支付方式枚举
export const payTypeMap = {
  1: "支付宝",
  2: "微信",
  3: "有钱花",
  4: "分付君",//收支明细接口 4代表分付君
  // 4: "民生分期"
}
//审核状态枚举
export const auditMap = {
  0:"审核中",
  1:"审核通过",
  2:"审核驳回",
  3:"审核撤销",
}

// 付款明细审核状态枚举
export const orderShenMap = {
  1:"待审核",
  2:"审核通过",
  3:"审核驳回",
  4:"审核通过",
  0:"审核撤销"
}
// 订单状态枚举
export const orderStatusMap = {
  11: '待支付',
  12: "支付中",
  13: "支付完成",
  14: "订单取消",
  15: "订单超时",
  16: "订单删除",
  17: "待签约",
  18: "待确认",
  19: "订单完成",
  20: "订单关闭"
}

// 订单售后状态枚举
export const afterSaleStatusMap = {
  0: "草稿",
  1: "审核中",
  2: "处理中",
  3: "审核驳回",
  4: "已完成",
  5: "处理异常",
  6: "撤回"
}

// 赛道类型枚举
export const businessMap = {
  0: "学历",
  1: "财经",
  4: "测试赛道",
  20: "教师资格证",
  911: "财会赛道"
}

// 订单渠道枚举
export const orderChannelMap = {
  1: "财经官网web端",
  2: "财经Android端",
  3: "财经ios端",
  4: "招生工作台",
  5: "财经h5",
  6: "学历官网web端",
  7: "学历Android端",
  8: "学历ios端",
  9: "学历h5",
  10: "招生工作台",
  11: "招生工作台",
  12: "预收款h5"
}
// 订单渠道枚举(旧)
// export const orderChannelMap = {
//   1: "恒企网校web端",
//   2: "恒企网校app端",
//   3: "恒企网校H5端",
//   4: "招生工作台"
// }
// 转介绍枚举
export const recommandMap = {
  0: "否",
  1: "是"
}
// 转介绍类型
export const recommandTypeMap = {
  0: "转介绍",
  1: "复购"
}

// 报名类型枚举
export const signUpTypeMap = {
  0: "正常",
  1: "已转班",
  2: "已转校",
  3: "已退学"
}

// 退费类型枚举
export const refundMap = {
  0: "待处理",
  1: "已处理",
  2: "处理异常"
}

// 类型状态枚举
export const processTypeMap = {
  0: "退费",
  1: "升班",
  2: "转班",
  3: "转校",
  4: "待提交",
  5: "待审核",
  6: "审核通过",
  7: "审核驳回",
  8: "关闭学习",
  9: "订单关闭",
  10: "权限关闭",
  11: "排课关闭",
  12: "关闭学习异常",
  13: "订单关闭异常",
  14: "权限关闭异常",
  15: "排课关闭异常",
  16: "退费完成",
  17: "退费处理中",
  18: "待支付",
  19: "支付中",
  20: "支付完成",
  21: "待确认",
  22: "订单完成",
  23: "已完成",
  31: "订单待关闭",
  32: "权限待关闭",
  33: "排课待关闭",
  34: "学习待关闭",
  35: "待关闭",
  36: "处理中",
  1001: "退费申请",
  1002: "升班申请",
  1003: "转班申请",
  1004: "转校申请",
  1005: "订单退费",
  1006: "升班订单",
  1007: "转班订单",
  1008: "转校订单",
  1999: "售后完成",
}
// 售后流程枚举
export const processSaleMap = {
  24: "提交申请",
  25: "售后审核",
  26: "原订单关闭",
  27: "新订单开通",
  28: "售后完成",
  29: "订单变更",
  30: "退费处理",
  31: "订单待关闭",
  32: "权限待关闭",
  33: "排课待关闭",
  34: "学习待关闭",
  1001: "退费申请",
  1002: "升班申请",
  1003: "转班申请",
  1004: "转校申请",
  1005: "订单退费",
  1006: "升班订单",
  1007: "转班订单",
  1008: "转校订单",
  1999: "售后完成",
  1009: "订单处理",
  2001: "申请审核",
  2002: "订单关闭",
  2003: "权限关闭",
  2004: "退费",
  2005: "创建订单",
  2006: "订单支付",
  2007: "签订协议",
  2008: "权限开通",
  2009: "提交申请",
  2010: "订单更新",
  2011: "内部结算",
  3001: "审核中",
  3002: "通过",
  3003: "驳回",
  3004: "待关闭",
  3005: "已关闭",
  3006: "关闭异常",
  3007: "待处理",
  3008: "已处理",
  3009: "处理异常",
  3010: "已创建",
  3011: "创建异常",
  3012: "待支付",
  3013: "支付中",
  3014: "支付完成",
  3015: "待签约",
  3016: "待开通",
  3017: "已开通",
  3018: "开通异常",
  3019: "异常",
  3020: "免签",
  3021: "处理完成",
  3023: "已提交",
  3024: "已更新",
  3025: "待更新",
  3026: "更新异常",
  3027: "撤回",
  3028: "待审",

}
// 售后类型枚举
export const afterSaleMap = {
  0: "退费",
  1: "升班",
  2: "转班",
  3: "转校"
}
//售后类型详细枚举
export const changeClassTypeMap = {
  0: "一升一",
  1: "升班 - 多升一",
  2: "一转一",
  3: "转班 - 多转一"
}


// 支付状态枚举
export const payStatusMap = {
  1: "待支付",
  2: "支付中",
  3: "支付完成",
  4: "订单取消",
  5: "订单超时",
  6: "贷款中",
  7: "贷款完成",
  8: "贷款失败",
}
// 订单类型枚举
export const orderTypeMap = {
  1: "报名新增",
  2: "升班新增",
  3: "转班新增",
  4: "免费报名"
}

// 贷款状态枚举
export const loanMap = {
  6: "贷款中",
  7: "贷款成功",
  8: "贷款失败"
}

// 排课状态枚举
export const courseStatusMap = {
  0: "待排课",
  1: "已排课",
  2: "已结课"
}
// 课程类型枚举
export const courseTypeMap = {
  0: "课程",
  1: "实习工具",
  2: "实训工具",
  3: "题库",
  4: "资料",
  5: "课程-面授",
  6: "课程-双师",
  7: "课程-直播",
  8: "课程-录播"
}
// 内容权限枚举
export const contentPermisionMap = {
  0: "待开通",
  1: "已开通",
  2: '开通失败'
}
export const contentPermisioncloseMap = {
  0: "待关闭",
  1: "已关闭",
  2: '关闭失败'
}

// 业务类型枚举
export const businessTypeMap = {
  0: "保险协议",
  1: "报名协议"
}

// 协议类型枚举
export const contractTypeMap = {
  0: "线上",
  1: "线下"
}

// 协议审批状态
export const contractApprovalStatusMap = {
  0: "待签约",
  1: "已签订",
  2: "待审核",
  3: "审核通过",
  4: "驳回"
}

// 呼叫类型枚举
export const callTypeMap = {

}
// 生成订单接口 区分3种 报名场景 1新增报名 2升班报名 3转班报名
export const WOKENBENCH_SIGNUP_TYPE = {
  CREATE: 1,
  UPDATE: 2,
  TRANSFER: 3
}

// 网校赛道映射
export const OSProjectMap = {
  0: "学历",
  1: "财经",
  4: '测试赛道',
  20: '教师资格证',
  911: "财会赛道"

}

// 协议审批状态类型枚举

export const contractStatusMap = {
  20: "线下签订",
  21: "免签",
  22: "待签约",
  23: "待审核",
  24: "通过",
  25: '驳回',
  26: '异常'
}
// 协议签署状态枚举
//21:免签；22:待签约;23:待审核;\24:通过；25:驳回；26:异常, bug7256 周彬贵提供枚举
export const contractSignMap = {
  21: "免签",
  22: "未签署",
  23: '已签署',
  // 24: '通过',
  // 25: '驳回',
  // 26: '异常',
}
// 退费类型
export const refundTypeMap = {
  1: "退学退费",
  2: "转班退费",
  3: "转班抵扣"
}

// 首付支付方式
export const downPaymentInstallmentTypeMap = {
  1: "支付宝",
  2: "微信支付"
}

// 首付收齐
export const isPayDownPaymentMap = {
  1: "是",
  0: "否"
}

// 贷款平台
export const installmentTypeMap = {
  0: "全款",
  1: "百度"
}
// 退费-退费方式
export const refundPayTypeEnum ={
  balance: 0,//余额
  bank: 1,//银行账户
  other: 2, // 第三方
}
// 退费-退费方式
export const refundPayTypeMap ={
  [refundPayTypeEnum.balance]: '学员余额',
  [refundPayTypeEnum.bank]: '银行账户',
  [refundPayTypeEnum.other]: '第三方',
}
// 贷款枚举
export const installmentTypeEnum = {
  full: 0,//全款
  yqh: 1//有钱花
}
// 贷款退费试算类型
export const refundTypeEnum ={
  dropout: 1,//售后退费
  tranfer: 4,//转班退费
}
/**
 * 售后类型（0退费，1升班，2转班，3转校）
 */
export const aftersaleTypeEnum = {
  dropout: 0,//退费
  upgrade: 1,//升班
  transfer: 2,//转班
  transferSchool: 3,//转校
}
/**
 * 赛道 0:学历 1:财经
 */
export const businessIdEnum = {
  xl: 0,//学历
  cj: 1,//财经
}
/**
 * 学历商品类型（1自考2成考3网教）
 */
export const xlTypeEnum = {
  zk: 1,
  ck: 2,
  wj: 3
}
/**
 * 商品模板（1.财经单品模板、2.自考单品模板、3.成考单品模板、4.网教单品模板、5.财经普通套餐、6.财经组合套餐、7.自考普通套餐
 */
export const goodsTemplateEnum ={
  cjdp: 1,
  zkdp: 2,
  ckdp: 3,
  wjdp: 4,
  cjpt: 5,
  cjzh: 6,
  zlpt: 7
}
// 成考志愿
export const volunteerEnum = {
  one: 1,//第一志愿
  second: 2,//第二志愿
}
// 出纳人列表测试数据
export const mockCashierChecker = (envUtil.isBeta() || envUtil.isDev() || envUtil.isTesting()) ?  [
  {userName: '樊伟炜', dingtalkUserId: '1957672534-355298284', tyUserId: '1957672534-355298284'},
  {userName: '赵浩', dingtalkUserId: '1621826137985295', tyUserId: '1621826137985295'},
  {userName: '林秀菊', dingtalkUserId: '1922025424-1872686467', tyUserId: '1922025424-1872686467'},
  {userName: '陈桃红', dingtalkUserId: '16034227472385148', tyUserId: '16034227472385148'},
  {userName: '覃智慧', dingtalkUserId: '2512152042-828443461', tyUserId: '2512152042-828443461'},
  {userName: '张慧飞', dingtalkUserId: '206718166124214391', tyUserId: '206718166124214391'},
  {userName: '邱林锋', dingtalkUserId: '16100758022538697', tyUserId: '16100758022538697'},
] : []
// 网校销售状态（0待上架,1下架,2在售）/为2时显示预览按钮
export const statusOnlineSellEnum = {
  pending: 0,
  off: 1,
  on: 2
}
/**
 * http://wiki.hqbis.com/docs/goods//94
 * 商品选择请求 选择途径（1网校后台，2全网班名，3全网转班，4全网升班，5成考第二志愿）
 */
export const selectGoodsTypeEnum = {
  wxBackend: 1,
  qwSignup: 2,
  qwTransfer: 3,
  qwUpgrade: 4,
  ckSecondGood: 5
}
// 选择商品弹窗类型
export const goodModalTypeEnum = {
  upgrade: 'upgrade',
  transfer: 'transfer',
  signup: 'signup'
}
/**
 * 商品类型 0单品 1套餐
 */
export const goodTypeEnum = {
  single: 0,
  package: 1,
}
/**
 * 报名类型：1新增报名、2升班报名、3转班报名
 */
export const orderTypeEnum = {
  newSignUp: 1,
  upgrade: 2,
  tranfer: 3
}
/**
 * 平台ID
 * 招生工作台:4
 */
export const schoolIdEnum = {
  zsgzt: 4
}
/**
 * 是否免费0:免费 1:付费
 */
export const freeEnum = {
  yes: 0,
  no: 1
}
/**
 * 订单详情是否为贷款
 */
export const installmentModeEnum = {
  noLoan: 0,//无贷款
  onlineLoan: 1,//线上贷款
  offlineLoan: 2,//线下贷款
}
/**
 * 付款明细
 * http://yapi.hqbis.com/project/156/interface/api/13022
 */
export const payTypeEnum = {
  balance: 0,//学员余额
  bank: 1,//银行账户
  loan: 2,//贷款
}
/**
 * 订单类型
 * 1:天翼 2:nc
 */
export const OrderOriginTypeEnum = {
  ty: 1,
  nc: 2,
}